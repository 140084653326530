import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Faq() {
  const renderSocialIcons = () => (
    <div>
      <a className="material-icon-prod-style-fix" href="https://www.facebook.com/maciej.dobosz.md" target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </a>
      <a className="material-icon-prod-style-fix" href="https://www.linkedin.com/in/maciej-dobosz-072851135/" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
      </a>
    </div>
  );

  return (
    <>
      <h1>FAQ</h1>
      <div className="faq margin-bottom">
        <h3>Czym jest spiker?</h3>
        Darmową platformą wymiany notatek dla uczniów/studentów.
        Każda osoba posiadająca specjalny link może wrzucać lub pobierać materiały.

        <h3>Czy spiker jest całkowicie darmowy?</h3>
        Tak.

        <h3>Czym są specjalne linki?</h3>
        Każda klasa/grupa dostaje osobny adres np.
        {' '}
        <b>twojagrupa.spiker.eu</b>
        , pod którym ma dostęp do wszystkich plików.
        Prosty do zapamiętania link jest idealny do dzielenia się z innymi.

        <h3>Gdzie znaleźć specjalny link do swojej klasy/grupy?</h3>
        Jeśli twoja klasa/grupa nie ma jeszcze swojego linku, lub gdy masz sugestie:
        {renderSocialIcons()}
      </div>
    </>
  );
}

export default Faq;
